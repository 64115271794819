import { render, staticRenderFns } from "./Classify.vue?vue&type=template&id=3e3066bc&scoped=true&"
import script from "./Classify.vue?vue&type=script&lang=js&"
export * from "./Classify.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e3066bc",
  null
  
)

export default component.exports