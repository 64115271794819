<template>
    <common-form
            ref="form"
            url="web/mall/classify"
            :title="title"
            :items="items"
            :edit="edit"
            :before-request="beforeRequest"
            @success="$emit('success')">
    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit:false,
                id:null,
            }
        },
        computed: {
            title(){
                return this.edit?"编辑分类":"添加分类"
            },
            items() {
                return [
                    {
                        type: "input",
                        name: "名称",
                        key: "name",
                        required: true,
                    },
                    {
                        type: "number",
                        name: "排序",
                        key: "sort",
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                this.id = model.id
                this.$refs.form.show(model)
            },
            beforeRequest(model){
                return {
                    ...model,
                    id:this.id,
                }
            }
        }
    }
</script>
