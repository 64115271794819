<template>
    <page-content>

        <common-table
                ref="table"
                path="web/mall/classify"
                :columns="columns"
        >

            <template slot="search">
                <a-button ghost type="primary" @click="add">新增</a-button>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <ClassifyEdit ref="classifyEdit" @success="getList"></ClassifyEdit>
    </page-content>
</template>

<script>

    import ClassifyEdit from './ClassifyEdit'

    export default {
        components: {
            ClassifyEdit
        },
        data() {
            return {
            }
        },

        computed: {
            columns() {
                return [
                    {
                        title: '分类名称',
                        dataIndex: 'name'
                    },
                    {
                        title: '排序',
                        dataIndex: 'sort'
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createdAt',
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add() {
                this.$refs.classifyEdit.show()
            },
            edit(record) {
                this.$refs.classifyEdit.show(record)
            },
            remove(record){
                let that = this
                this.$confirm({
                    title: '是否删除该记录?',
                    content: '该操作不可撤销',
                    centered: true,
                    onOk () {
                        that.$delete('web/mall/classify/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },
            getList() {
                this.$refs.table.getData()
            },
        }
    }
</script>

<style scoped>

</style>
